import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscriber } from 'rxjs';
import { User } from 'src/app/core/models/user';
import { CompanyRepository } from 'src/app/core/repository/company.repository';
import { UserRepository } from 'src/app/core/repository/user.repository';
import { AccountService } from 'src/app/home/components/services/account.service';
import { Category } from 'src/app/shopping/models/category';
import { Product } from 'src/app/shopping/models/product';
import { CategoryService } from 'src/app/shopping/services/category.service';
import Swal from 'sweetalert2';
import { SearchService } from '../../services/search-service.service';
import { SystemStoreService } from '../../services/sistema-loja';
import { UrlService } from '../../services/url.service';
import { ViewCartService } from '../../services/view-cart.service';
import { CartProduct, ViewCartProducts } from 'src/app/shopping/models/cart-produt';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  phoneConactDescription: string;
  user: User | null;
  oForm: FormGroup;

  logoImageUrl: SafeResourceUrl = '';
  aCategories: Array<Category> = new Array<Category>();
  aCartProducts: Array<CartProduct> | null = new Array<CartProduct>();
  sFotoPerfil: string | null;
  validateImageUrl: string;
  nTotalValueProducts: number;

  constructor(
    oFormBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private oUrlService: UrlService,
    private domSanitizer: DomSanitizer,
    private translate: TranslateService,
    private oSearchService: SearchService,
    private userRepository: UserRepository,
    private categoryService: CategoryService,
    private oViewCartService: ViewCartService,
    private companyRepository: CompanyRepository,
    private oSystemStoreService: SystemStoreService,
    private oAccountService: AccountService
  ) {
    this.oForm = oFormBuilder.group({
      edtNameProduct: ['', []]
    });

    this.user = this.userRepository.getUser();

    this.getProdutosCarrinho().subscribe();

    this.userRepository.userSource$.subscribe(user => {
      this.user = user;
      setTimeout(() => {
        if (user) {
          this.buscarFotoPerfil();
        } else {
          this.sFotoPerfil = null;
        }
      });
    });

    this.userRepository.userNameSource.subscribe((value) => {
      if (value && this.user) {
        this.user.name = value;
      }
    });

    this.companyRepository.companySource.subscribe(company => {
      if (company) {
        this.logoImageUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(company.logoImageUrl);
        this.validateImageUrl = company.logoImageUrl;
      }
    });

    this.oViewCartService.cartSource$.subscribe((cart: Product[] | null) => {
      if (cart) {
        if (this.user) {
          this.getProdutosCarrinho().subscribe();
        } else {
          this.aCartProducts = this.oViewCartService.getCartStorage();
          this.nTotalValueProducts = this.getTotalValueProducts();
        }
      }
    });

  }

  ngOnInit(): void {
    this.getCategories();

    this.oSystemStoreService.getparametroSistemaLojaListByRequest().subscribe((datas) => {
      for (let i = 0; i < datas.lines.length; i++) {
        const item = datas.lines[i];
        switch (item.sgTpParametroSistema) {
          case 'DS_TELEFONE':
            this.phoneConactDescription = item.vlParametroSistema;
            break;
          default:
            break;
        }
      }
    });
  }

  onViewCart() {
    this.router.navigate(['/view-cart']);
  }

  onLoad() {
    if (this.user) {
      this.router.navigate(['/checkout']);
    } else {
      this.oUrlService.setUrlRoute(this.router.url);
      this.router.navigate(['/login']);
    }
  }

  onSubmitSearch() {
    this.oSearchService.objSearch.next({ name: this.edtNameProduct.value });

    if (!this.router.url.includes('shop-by-categories')) {
      this.router.navigate(['/shop-by-categories/list']);
    }
  }

  getTotalValueProducts(): number {
    if (this.aCartProducts) {
      let totalValue = 0;

      for (let index = 0; index < this.aCartProducts.length; index++) {
        const product = this.aCartProducts[index];
        totalValue = (totalValue + (product.value * product.quantityViewCart));
      }
      return totalValue
    }
    return 0;
  }

  onRemove(product: CartProduct) {
    if (this.aCartProducts) {
      const index = this.aCartProducts.findIndex((item: Product) => item.id === product.id);

      if (index >= 0) {
        this.aCartProducts.splice(index, 1);

        if (this.user) {
          if (product.idViewCart) {
            this.oViewCartService.deleteCart(product.idViewCart).subscribe();
          }
        } else {
          this.oViewCartService.delCartStorage(product.id);
        }
      }
    }
  }

  logout() {
    Swal.fire({
      html: `
        <div style="padding:10px;">
          <img src="./assets/images/inner-page/icons8-logout-96.png" style="max-width:170px;" />
          <h4 class="mt-4 mb-2"><b>${this.translate.instant('header.confirm_logout_title')}</b></h4>
          <div class="d-block mt-2 text-muted">
            <div>
            ${this.translate.instant('header.confirm_logout_message')}
            </div>
          </div>
        </div>
      `,
      // title: this.translate.instant('common.warning'),
      // text: this.translate.instant('header.confirm_logout_message'),
      showCancelButton: true,
      confirmButtonText: this.translate.instant('common.yes'),
      cancelButtonText: this.translate.instant('common.no'),
      reverseButtons: true,
      showClass: {
        popup: 'animated fadeInDown',
      },
      hideClass: {
        popup: 'animated fadeOutUp',
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/logout']);
      }
    });
  }

  goToSearch() {
    const el: any = document.getElementById('searchTerms');
    if (el && el.value) {
      window.location.href = '/search?' + el.value;
    } else if (el) {
      el.focus();
    }
  }

  getCategories() {
    this.categoryService.getCategoryListByRequest().subscribe((data: any) => {
      this.aCategories = data;
    });
  }

  getImageUrl(id: number): SafeResourceUrl | null {
    if (this.aCartProducts) {
      const item = this.aCartProducts.find((prod: Product) => prod.id === id);
      if (item && item.imageUrl) {
        return item.imageUrl;
      }
      return null;
    }
    return null;
  }

  onLoadShopCategories(item: Category) {
    if (item) {
      this.router.navigate(['/shop-by-categories/', item.name.toLocaleLowerCase()], { relativeTo: this.activatedRoute });
    }
  }

  private buscarFotoPerfil() {
    this.oAccountService.getFotoPerfil().subscribe((ret: any) => {
      if (ret && ret.lines) {
        ret.lines.map((result: any) => {
          this.sFotoPerfil = this.oAccountService.renderImage(result.itens[0].contentId);
        })
      }
    });
  }

  private getProdutosCarrinho() {
    return new Observable<any>((oObserver: Subscriber<any>) => {
      if (this.user) {
        this.oViewCartService.getCart().subscribe((res: ViewCartProducts) => {
          this.aCartProducts = res.produtos;
          this.nTotalValueProducts = res.vlTotal;

          oObserver.next(res);
          oObserver.complete();
        });
      } else {
        this.aCartProducts = this.oViewCartService.getCartStorage();
        this.nTotalValueProducts = this.getTotalValueProducts();

        oObserver.next();
        oObserver.complete();
      }
    });
  }

  get edtNameProduct(): FormControl {
    return this.oForm.get('edtNameProduct') as FormControl;
  }
}
