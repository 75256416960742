import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { UserCredential } from '../models/user-credential';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CompanyService } from './company.service';
import { UserRepository } from '../repository/user.repository';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { CompanyRepository } from '../repository/company.repository';
import { Router } from '@angular/router';
import { settings } from 'src/settings';
import { eTipoContato } from 'src/app/shared/enums/tipo-contato';
import { IRecuperacaoSenha } from 'src/app/shared/model/recuperacao-senha';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private readonly API_USER = '/utilizador';
  private readonly API_TOKEN = '/token';
  private readonly API_PROFILE = '/perfil';
  private readonly API_REVOKE = '/revoke';
  private readonly API_AUTHORIZE = '/utilizador/registarUtlUrl';

  constructor(
    private http: HttpClient,
    private companyService: CompanyService,
    private userRepository: UserRepository,
    private translate: TranslateService,
    private router: Router,
    private companyRepository: CompanyRepository
  ) { }

  token(username: string, password: string): Observable<any> {
    return new Observable<any>(observer => {
      this.getMainCredentials(username, password).subscribe(mainCredentials => {
        if (mainCredentials) {
          // Creates temporary credentials if you have access to the SIAF system.
          this.getCompanyCredentials(mainCredentials).pipe(catchError(error => {
            Swal.fire({
              html: `
                <div style="padding:30px;text-align:left;">
                  <img src="./assets/images/inner-page/siaf.png" style="max-width:170px;" />
                  <h4 class="mt-4 mb-2"><b>${this.translate.instant('login.authorize_acess')}</b></h4>
                  <div class="d-block mt-2 text-muted">
                    <div>
                    ${this.translate.instant('login.authorize_acess_message')}
                    </div>
                    <ul class="p-3 mt-3 card">
                      <li class="d-block">- ${this.translate.instant('login.name')}</li>
                      <li class="d-block">- ${this.translate.instant('login.email_info')}</li>
                      <li class="d-block">- ${this.translate.instant('login.phone_info')}</li>
                      <li class="d-block">- ${this.translate.instant('login.address_info')}</li>
                    </ul>
                  </div>
                </div>
              `,
              showCloseButton: true,
              showCancelButton: true,
              confirmButtonText: this.translate.instant('login.yes_authorize'),
              cancelButtonText: this.translate.instant('common.no'),
              showClass: {
                popup: 'animated fadeInDown',
              },
              hideClass: {
                popup: 'animated fadeOutUp',
              }
            }).then(result => {
              if (result.isConfirmed) {
                this.authorizeUser(mainCredentials).subscribe(authorized => {
                  if (authorized) {
                    this.getMainCredentials(username, password).subscribe(mainInternalCredentials => {
                      this.getCompanyCredentials(mainInternalCredentials).subscribe(userCompanyAccessList => {
                        if (userCompanyAccessList && this.companyService.company) {
                          try {
                            this.finishLoginFlow(observer, userCompanyAccessList, password);
                          } catch (error) {
                            throw new Error('Not possible get user by company!');
                          }
                        }
                      });
                    });
                  } else {
                    Swal.fire(
                      {
                        iconHtml: '<img style="height: 160px;background-color: #ffff;padding: 15px 0;" src="./assets/images/error.png">',
                        title: this.translate.instant('common.error'),
                        text: 'Erro ao tentar autorizar',
                        showClass: {
                          popup: 'animated fadeInDown',
                        },
                        hideClass: {
                          popup: 'animated fadeOutUp',
                        },
                        confirmButtonText:
                          '<span style="width: 150px;">Ok</span>',
                      }
                    );
                  }
                });
              }
            });
            return of(null);
          })).subscribe(userCompanyAccessList => {
            if (userCompanyAccessList && this.companyService.company) {
              try {
                this.finishLoginFlow(observer, userCompanyAccessList, password);
              } catch (error) {
                throw new Error('Not possible get user by company!');
              }
            }
          });
        } else {
          observer.next();
          observer.complete();
        }
      });
    });
  }

  public finishLoginFlow(observer: any, userCompanyAccessList: any, password: string) {
    // Get credentials by Company
    const { username, email, activo, movel } = userCompanyAccessList;

    // Login with final credentials
    this.getMainCredentials(username, password).subscribe(finalUser => {
      Object.assign(finalUser, { created_at: Date.now() });
      const rawUser = { username: username, email: email, activo: activo, credential: finalUser, phone: movel };
      const user = this.userRepository.create(rawUser);
      this.userRepository.save(user);

      // Ger User Profile info
      this.getUserProfile().subscribe((profile: { lines: any[] }) => {
        const userUpdateProfile = this.userRepository.getUser();
        if (userUpdateProfile && profile && profile.lines && profile.lines.length) {
          userUpdateProfile.id = profile.lines[0]['idPessoa'];
          userUpdateProfile.name = profile.lines[0]['noPessoa'];
          Object.assign(userUpdateProfile, { profile: this.userRepository.createUserProfile(profile.lines[0]) });
          this.userRepository.save(userUpdateProfile);

          // Get Address user
          this.getUserAddress().subscribe(address => {
            const userUpdateAddress = this.userRepository.getUser();
            if (userUpdateAddress && address && address['lines'] && address['lines'].length) {
              const mainAddress = address.lines.find((item: any) => item.flPrincipal.codigo === 'S');
              Object.assign(userUpdateAddress, { address: this.userRepository.createUserAddress(mainAddress || address.lines[0]) });
              this.userRepository.save(userUpdateAddress);

              observer.next(true);
              observer.complete();
            } else {
              // User is allowed follow when he not have address
              observer.next(true);
              observer.complete();
            }
          });
        } else {
          throw new Error('Not possible get user profile!');
        }
      });
    });
  }

  private getMainCredentials(username: string, password: string): Observable<any> {
    const encodedPass = encodeURIComponent(password);
    const encodedUsername = encodeURIComponent(username);
    const params = `grant_type=password&username=SIAF/${encodedUsername}@carbon.super&password=${encodedPass}&scope=device_${Date.now()} ${settings.scopes}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Authorization': `Basic ${settings.endpoint.identityKey}`
    });
    return this.http.post(this.API_TOKEN, params, { headers }).pipe(
      map(res => Object.assign(res, { created_at: Date.now(), username: username })),
      catchError(error => of(null))
    );
  }

  private getCompanyCredentials(userCredential: UserCredential): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Authorization': `${userCredential.token_type} ${userCredential.access_token}`
    });
    let oParams = new HttpParams();
    if (this.companyService.company) {
      oParams = oParams.set('sgPessoaSiaf', this.companyService.company.id);
    }
    return this.http.get(`${this.API_USER}/acessos`, { headers, params: oParams });
  }

  private authorizeUser(userCredential: UserCredential): Observable<any> {
    const params = {
      url: this.companyRepository.url()
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      'Authorization': `Bearer ${userCredential?.access_token}`
    });
    return this.http.post(this.API_AUTHORIZE, params, { headers });
  }

  private getUserProfile(): Observable<any> {
    const oParams = {} as any;
    oParams['flPerfil'] = 'S';
    return this.http.get(`${this.API_PROFILE}/getPessoaSingular`, { params: oParams });
  }

  private getUserAddress(): Observable<any> {
    return this.http.get(`${this.API_PROFILE}/lstPessoaEndereco`);
  }

  public recoveryPassword(type: string, data: IRecuperacaoSenha): Observable<any> {
    const params = {} as any;

    if (type === eTipoContato.EMAIL) {
      params['dsEmail'] = data.dsEmail;
    }
    if (type === eTipoContato.TELEMOVEL) {
      params['dsMovel'] = `${data.dsCodigo}${data.dsMovel}`;
    }
    params['url'] = this.companyRepository.url();

    return this.http.put(this.API_USER + '/redefinirPalavraPasse', params);
  }

  public resetPassword(data: IRecuperacaoSenha, type: string | null): Observable<any> {
    const params = {} as any;

    if (type === eTipoContato.TELEMOVEL) {
      params['cdValidacao'] = data.cdValidacao?.replace(/\s/g, '');
      params['dsMovel'] = data.dsMovel;
    } else {
      params['dsToken'] = data.dsToken;
    }
    params['dsSenha'] = data.dsSenha;
    
    return this.http.put(this.API_USER + '/salvarPalavraPasse', params);
  }

  public changePassword(user: string, password: string, newPassword: string): Observable<any> {
    const param = { 'noUtilizador': user, 'dsSenha': password, 'dsSenhaNova': newPassword };
    return this.http.put(this.API_USER + '/trocaSenha', param);
  }

  public documentValidation(nif: string): Observable<any> {
    const param = { 'nuDocumento': nif, 'url': this.companyRepository.url() };
    return this.http.get(`${this.API_USER}/validaDocAutoRegisto`, { params: param }).pipe(catchError(error => of(null)));
  }

  public showErrorLogin() {
    Swal.fire({
      html: `
        <div style="padding:30px;">
          <img src="./assets/images/inner-page/face-credential.png" style="max-width:100px; margin: 20px auto;" />
          <h3 class="m-3 text-danger">${this.translate.instant('common.login_error')}</h3>
          <span>${this.translate.instant('common.invalid_credentials')}</span>
        </div>
      `,
      showCloseButton: true,
      confirmButtonText: 'Tentar novamente',
      showClass: {
        popup: 'animated fadeInDown',
      },
      hideClass: {
        popup: 'animated fadeOutUp',
      }
    });
  }

  public revoke(): Observable<any> {
    const user = this.userRepository.getUser();
    const params = `token=${user?.credential.access_token}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'Authorization': `Basic ${settings.endpoint.identityKey}`
    });
    return this.http.post(this.API_REVOKE, params, { headers }).pipe(
      catchError(error => of(null))
    );
  }

  public logout() {
    this.userRepository.remove();
    let urlRedirect = '/';
    this.router.navigateByUrl(urlRedirect);
  }

}
