import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";


@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
  ) { }

  public showCommonError(error: HttpErrorResponse) {
    if (error && error.error && error.error.message) {
      Swal.fire({
        iconHtml: '<img style="height: 160px;background-color: #ffff;padding: 15px 0;" src="./assets/images/warning.png">',
        title: this.translate.instant('common.warning'),
        text: error.error.message,
        showClass: {
          popup: 'animated fadeInDown',
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
        confirmButtonText:
          '<span style="width: 150px;">Ok</span>',
      });
    } else if (error && error.error && error.error.erro[0].message) {
      Swal.fire({
        iconHtml: '<img style="height: 160px;background-color: #ffff;padding: 15px 0;" src="./assets/images/warning.png">',
        title: this.translate.instant('common.warning'),
        text: error.error.erro[0].message,
        showClass: {
          popup: 'animated fadeInDown',
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
        confirmButtonText:
          '<span style="width: 150px;">Ok</span>',
      });
    } else {
      Swal.fire({
        iconHtml: '<img style="height: 160px;background-color: #ffff;padding: 15px 0;" src="./assets/images/warning.png">',
        title: this.translate.instant('common.warning'),
        text: this.translate.instant('common.http_error_message'),
        showClass: {
          popup: 'animated fadeInDown',
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
        confirmButtonText:
          '<span style="width: 150px;">Ok</span>',
      });
    }
  }

  public showAgeError(error: HttpErrorResponse) {
    if (error && error.error && error.error.message) {
      Swal.fire({
        iconHtml: '<img style="height: 160px;background-color: #ffff;padding: 15px 0;" src="./assets/images/warning.png">',
        title: this.translate.instant('common.warning'),
        text: error.error.message,
        showClass: {
          popup: 'animated fadeInDown',
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
        confirmButtonText:
          '<span style="width: 150px;">Ok</span>',
      });
    }
  }


  public showAgeErrorAdmin(error: HttpErrorResponse) {
    if (error && error.error && error.error.fault.message) {
      Swal.fire({
        iconHtml: '<img style="height: 160px;background-color: #ffff;padding: 15px 0;" src="./assets/images/warning.png">',
        title: this.translate.instant('common.warning'),
        text: error.error.fault.message,
        showClass: {
          popup: 'animated fadeInDown',
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
        confirmButtonText:
          '<span style="width: 150px;">Ok</span>',
      });
    }
  }

  public showCheckoutError(error: HttpErrorResponse) {
    if (error && error.error && error.error.message) {
      Swal.fire({
        iconHtml: '<img style="height: 160px;background-color: #ffff;padding: 15px 0;" src="./assets/images/warning.png">',
        title: this.translate.instant('common.warning'),
        text: error.error.message,
        showClass: {
          popup: 'animated fadeInDown',
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
        confirmButtonText:
          '<span style="width: 150px;">Ok</span>',
      });
    }
  }

  public showCheckoutErrorFrame(error: HttpErrorResponse) {
    if (error && error.error && error.error.message) {
      Swal.fire({
        iconHtml: '<img style="height: 160px;background-color: #ffff;padding: 15px 0;" src="./assets/images/warning.png">',
        title: this.translate.instant('common.warning'),
        text: error.error.message,
        showClass: {
          popup: 'animated fadeInDown',
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
        confirmButtonText:
          '<span style="width: 150px;">Ok</span>',
      });
    }
    this.spinner.hide();
  }

  public showErrorPrice(error: HttpErrorResponse) {
    if (error && error.error && error.error.message) {
      Swal.fire({
        iconHtml: '<img style="height: 160px;background-color: #ffff;padding: 15px 0;" src="./assets/images/warning.png">',
        title: this.translate.instant('common.warning'),
        text: error.error.message,
        showClass: {
          popup: 'animated fadeInDown',
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
        confirmButtonText:
          '<span style="width: 150px;">Ok</span>',
      });
    }
  }

  public showErrorPayment(error: HttpErrorResponse) {
    if (error && error.error && error.error.message) {
      Swal.fire({
        iconHtml: '<img style="height: 160px;background-color: #ffff;padding: 15px 0;" src="./assets/images/warning.png">',
        title: this.translate.instant('common.warning'),
        text: error.error.message,
        showClass: {
          popup: 'animated fadeInDown',
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
        confirmButtonText:
          '<span style="width: 150px;">Ok</span>',
      });
    }
  }

  public showCommonErrorUser(error: HttpErrorResponse) {
    if (error && error.error && error.error.message) {

      let messageUserEmail = '';
      if (error.error.users && error.error.users.length) {
        error.error.users.forEach((element: any, index: number) => {
          const separator = ((index === 0) ? '' : ' | ');
          messageUserEmail += `${separator}${element}`
        });

        messageUserEmail = `Realize o acesso com: ${messageUserEmail}`;
      }

      Swal.fire({
        iconHtml: '<img style="height: 160px;background-color: #ffff;padding: 15px 0;" src="./assets/images/warning.png">',
        title: this.translate.instant('common.warning'),
        text: `${error.error.message} ${messageUserEmail}`,
        showClass: {
          popup: 'animated fadeInDown',
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
        confirmButtonText:
          '<span style="width: 150px;">Ok</span>',
      });
    } else {
      Swal.fire({
        iconHtml: '<img style="height: 160px;background-color: #ffff;padding: 15px 0;" src="./assets/images/warning.png">',
        title: this.translate.instant('common.warning'),
        text: this.translate.instant('common.http_error_message'),
        showClass: {
          popup: 'animated fadeInDown',
        },
        hideClass: {
          popup: 'animated fadeOutUp',
        },
        confirmButtonText:
          '<span style="width: 150px;">Ok</span>',
      });
    }
  }

}
